@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

/* ------ Add your CSS below this line (or @import your other CSS files). ------- */

#header-bg {

  background-color: black;
  opacity: 1;
}

[x-cloak] {
  display: none !important;
}

.body__line {
  width: 1px;
  height: 38px;
  margin: 0 auto;
}

.body__line__mobile {
  width: 1px;
  height: 38px;
  margin: 0 auto;
}

.body__line.white,
.body__line__mobile.white{
  background-color: #ffffff;
}

.body__line.black,
.body__line__mobile.black {
  background-color: #000000;
}

.pd-icon__button {
  z-index: 1;
}

.pd-icon__button:after {
  content: "";
  position: absolute;
  width: 1px;
  height: 100%;
  z-index: -1;
}

.pd-icon__button--open:after {
  content: "";
  position: absolute;
  width: 1px;
  height: 140%;
  z-index: -1;
}

.pd-icon__button.pd-black:after {
  background: black;
}

.pd-icon__button.pd-white:after {
  background: white;
}

@media (min-width: 768px) {
  .pd-icon__button:after {
    content: "";
    position: absolute;
    width: 180%;
    height: 1px;
    z-index: -1;
    top: 50%;
  }
  .pd-icon__button--open:after {
    content: "";
    position: absolute;
    width: 180%;
    height: 1px;
    z-index: -1;
    top: 50%;
  }
}

.pd-icons-link:visited {
  color: white !important;
}

.pd-timeline__mobile__body {
  display: block;
}

@media (min-width: 768px) {
  .pd-timeline__mobile__body {
    display: none;
  }
}

.pd-timeline__body {
  display: none;
}

@media (min-width: 768px) {
  .pd-timeline__body {
    display: block;
  }
}



.pd-no__after:after {
  content: none;
}

.pd-hidden {
  display: none;
}

.timeline-list:after {
  content: '';
  display: block;
  width: 100%;
  height: 1px;
  background-color: #fff;
  position: absolute;
  left: 0;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  z-index: 0;
}

.pd-form__embed {
  width: 100%;
}

.pd-form__embed > div:first-of-type {
  width: 100% !important;
}

.pd-form__embed > div:first-of-type form {
  width: 100% !important;
}

.pd-heading {
  font-weight: normal !important;
}

.solid-base-image {
  height: 280px !important;
}